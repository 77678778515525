import Images from "~data/imageImports";
const serviceData = {
    services: [
        {
          id:"ms1",
          icon: Images.marketing.servicesIcon1,
          iconColor: "#ffd166",
          title: "Нетворкинг мероприятия",
          text:
            "Проводим мастер-классы, конференции,<br class='d-none d-lg-block'> воркшопы и встречи для разработчиков.",
        },
        {
          id:"ms2",
          icon: Images.marketing.servicesIcon2,
          iconColor:"#96f7d2",
          title: "Соревнования",
          text:
            "Организуем соревнования, где сможете <br class='d-none d-lg-block'> выграть крутые призы с вашей командой.",
        },
        {
          id:"ms3",
          icon: Images.marketing.servicesIcon3,
          iconColor:"#a9d2ff",
          title: "Стажировки",
          text:
            "Предлагаем реальные проекты <br class='d-none d-lg-block'> для продвинутых студентов",
        },
      ]
  }
  export default serviceData;