import React, { useEffect, useState } from "react"
import { StaticImage as Img } from "gatsby-plugin-image"
import HeroImageGroup from "./style"

export default function ImageGroup() {
  return (
    <>
      <HeroImageGroup>
        <HeroImageGroup.Inner>
          <Img
            src="../../../../../assets/image/marketing/l1-hero-img-ipad.png"
            className="welcome-image--group-01__main"
            placeholder="blurred"
            layout="fullWidth"
            alt="hero image main"
          />
        </HeroImageGroup.Inner>
      </HeroImageGroup>
    </>
  )
}
