import { Link } from '~components'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Images } from '~data'
import Footer from "./style"
export default function FooterOne(){
return(
    <Footer backgroundColor="#6a26da">
    <Container>
        <Footer.Box pbXL="95px">
        <Row>
            <Col xs="12" className="col-lg-4 col-md-8 col-xs-10">
            <Footer.Widgets className="footer-widgets footer-widgets--l7">
                {/* Brand Logo*/}
                <Footer.Box mb="30px">
                    <Link  to="#">
                        <img src={Images.FooterOne.LogoWhite} alt="logo" />
                    </Link>
                </Footer.Box>
                <Footer.Text mb="36px">
                Единственный центр программистов<br className="d-none d-xl-block" /> в Самарканде, где предлагают курсы,<br className="d-none d-xl-block" /> ивенты, соревнования и стажировки.
                </Footer.Text>

            </Footer.Widgets>
            </Col>
            <Col xs="12" className="col-xl-8">
            <Row>

                <Col xs="6" className="col-md-4 col-xs-6">
                <Footer.Widgets>
                    <Footer.Title>О школе</Footer.Title>
                    <Footer.List>
                        <Footer.ListItems>
                            <a href="about/about-us">О нас</a>
                        </Footer.ListItems>
                        {/* <Footer.ListItems>
                            <a href="about/testimonials-1">Отзывы</a>
                        </Footer.ListItems> */}
                         <Footer.ListItems>
                            <a href="about/contact">Контакты</a>
                        </Footer.ListItems>
                        {/*<Footer.ListItems>
                            <a href="#">Rider</a>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <a href="#">Contact</a>
                        </Footer.ListItems> */}
                    </Footer.List>
                </Footer.Widgets>
                </Col>
                <Col xs="6" className="col-md-4 col-xs-6">
                <Footer.Widgets>
                    <Footer.Title>Наши курсы</Footer.Title>
                    <Footer.List>
                        <Footer.ListItems>
                            <a href="courses/frontend">Фронтэнд</a>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <a href="courses/backend">Бекэнд</a>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <a href="courses/full-stack">Фулл-стек</a>
                        </Footer.ListItems>
                        {/* <Footer.ListItems>
                            <a href="#">Rider</a>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <a href="#">Contact</a>
                        </Footer.ListItems> */}
                    </Footer.List>
                </Footer.Widgets>
                </Col>
                <Col xs="12" className="col-md-4 col-xs-6">
                    <Footer.Widgets className="footer-widgets">
                        <Footer.Title>Контакты</Footer.Title>
                        <Footer.Address className="widgets-address">
                            <Footer.AddressItem>
                                <i className="fa fa-map-marker-alt" />
                                <span>Самарканд, ул Мухаммад<br className="d-block" />
                                Аль-Хорезми, 113 <br className="d-block" />
                                Бизнес центр, эт.5<br className="d-block" /></span>
                            </Footer.AddressItem>
                            <Footer.AddressItem>
                                <i className="fa fa-phone-alt" />
                                
                                <a href="#">+998 (95) 680-77-77
                                <span className="d-block"> Пн-Сб - 10:00 - 19:00</span> </a>
                            </Footer.AddressItem>
                            <Footer.AddressItem>
                                <i className="fa fa-envelope" />
                                <a href="mailto:info@nextgen.uz">info@nextgen.uz</a>
                            </Footer.AddressItem>
                        </Footer.Address>
                    </Footer.Widgets>
                </Col>

            </Row>
            </Col>
        </Row>
        </Footer.Box>
        <Footer.Copyright>
        <Footer.CopyrightText>© 2021 Next Generation. All Rights Reserved</Footer.CopyrightText>
            <Footer.SocialShare>
                <Footer.SocialShareItem>
                    <a href="https://www.instagram.com/nextgen.uz/">
                    <i className="fab fa-instagram" />
                    </a>
                </Footer.SocialShareItem>
                <Footer.SocialShareItem>
                    <a href="https://www.facebook.com/nextgen.uz">
                    <i className="fab fa-facebook-square" />
                    </a>
                </Footer.SocialShareItem>
                <Footer.SocialShareItem>
                    <a href="https://t.me/nextgen_uz">
                    <i className="fab fa-telegram" />
                    </a>
                </Footer.SocialShareItem>
                <Footer.SocialShareItem>
                    <a href="https://linkedin.com/company/nextgen-uz">
                    <i className="fab fa-linkedin" />
                    </a>
                </Footer.SocialShareItem>
            </Footer.SocialShare>
        </Footer.Copyright>
    </Container>
    </Footer>
)
}