import React from "react"
import Popup from "../components/Popup"
import { useState } from 'react';
import { PageWrapper } from "~components/Core"
import HeroSection from "~sections/marketing/Hero"
import ServiceSection from "~sections/marketing/Service"
import FeatureSection from "~sections/marketing/Features"
import ContentSectionOne from "~sections/marketing/ContentOne"
// import TestimonialSection from "~sections/marketing/Testimonial"
// import CounterSection from "~sections/marketing/Counter"
import FooterOne from "~sections/marketing/FooterOne"
import HeaderButton from "~sections/marketing/Header"

const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:true,
  // customLogo: Images.HeaderLogo,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnTwoText="Записаться"
      mr="15px"
      mrLG="0"
    />
  ),
}

export default function Marketing() {  
  return (
    <PageWrapper headerConfig={header}>
      <HeroSection/>
      <FeatureSection/>
      <ServiceSection/>
      <ContentSectionOne/>
      {/* <TestimonialSection /> */}
      {/* <CounterSection/> */}
      <FooterOne/>
    </PageWrapper>
  )
}
