import React from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Images } from "~data";
import TabContentWidget from "./Component/TabContentWidget";
import TabNavWidget from "./Component/TabNavWidget";
import Feature from "./style";

const toFrontend = <a style={{color: '#fff'}} href="./courses/frontend">Узнать больше</a>;
const toBackend = <a style={{color: '#fff'}} href="./courses/backend">Узнать больше</a>;
const toFullstack = <a style={{color: '#fff'}} href="./courses/full-stack">Узнать больше</a>;

// var linkStyle = document.createElement('style');
// linkStyle.innerHTML = 'a { color: yellow; font-weight: bold }';
// document.head.appendChild(linkStyle);

const FeatureSection = ({ ...rest }) => {
  return (
    <Feature>
      <Feature.Shapes>
        <img src={Images.marketing.featureShape1} alt="shape1" className="w-100"/>
        <Feature.ShapeTwo>
          <img src={Images.marketing.featureShape2} className="w-100" alt="shape1"/>
        </Feature.ShapeTwo> 
      </Feature.Shapes>
      
      <Container>
        <Row>
          <Col xs="auto" className="col-xl-8 col-lg-10">
            <Feature.Box mb="35px" mbMD="80px" mbLG="148px">
              <Feature.Title className="mb-5" as="h2" fontColor="#fff">
                Мы предлагаем <br className="d-none d-xs-block" /> следующие курсы
              </Feature.Title>
              <Feature.Text className="mt-8" fontColor="#fff">
                Чему вы научитесь:
              </Feature.Text>
            </Feature.Box>
          </Col>
        </Row>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="first"
        >
          <Feature.Tab>
            <Col className="col-xxl-3 col-lg-3 col-md-12 col-xs-10 col-12">
              <Feature.TabNavWrapper className="nav row ms-0 me-0 justify-content-center" as={Nav}>
                <Nav.Link
                  eventKey="first"
                  className="nav-item col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                >
                  <TabNavWidget iconClass="fas fa-file-code" text="Фронтэнд" className="nav-widget" mb="30px" mbLG="50px"/>
                </Nav.Link>
                <Nav.Link
                  eventKey="second"
                  className="col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                >
                   <TabNavWidget iconClass="fas fa-server" text="Бекэнд" className="nav-widget" mb="30px" mbLG="50px"/>
                </Nav.Link>
                <Nav.Link
                  eventKey="third"
                  className="widget widget--feature nav-item col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                >
                  <TabNavWidget iconClass="fas fa-code" text="Фулл-стек" className="nav-widget" mb="30px" mbLG="50px"/>
                </Nav.Link>
              </Feature.TabNavWrapper>
            </Col>
            <Col xs="auto" className="col-xxl-9 col-lg-9 col-md-12 col-sm-12">
            <Tab.Content className="tab-content tab-content--feature">
              <Tab.Pane eventKey="first">
                <Row className="align-items-center">
                  <Col
                    className="col-md-6 col-sm-6 col-xs-9"
                    xs="auto"
                  >
                    <TabContentWidget count="01." title="Качественной верстке сайтов" text={'Узнаете, как разрабатывать сайты с<br class="d-none d-lg-block" /> помощью HTML и CSS для всех <br class="d-none d-lg-block" /> актуальных версий браузеров и <br class="d-none d-lg-block" /> платформ.'}/>
                  </Col>
                  <Col
                    className="col-md-6 col-sm-6 col-xs-9"
                    xs="auto"
                  >
                    <TabContentWidget count="02." title="Программированию на Javascript" text={'Научитесь создавать программы на<br class="d-none d-lg-block" /> Javascript в разных стилях: <br class="d-none d-lg-block" /> императивном, объектном и <br class="d-none d-lg-block" /> функциональном.'}/>
                  </Col>
                  <div>{toFrontend}</div>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Row>
                  <Col
                    className="col-md-6 col-sm-6 col-xs-9"
                    xs="auto"
                  >
                    <TabContentWidget count="03." title="Писать сложные программы на Python" text={'Изучите основы языка Python.<br class="d-none d-lg-block" /> Также, будете понимать такие<br class="d-none d-lg-block" /> концепции как функциональное <br class="d-none d-lg-block" /> программирование и ООП.'}/>
                  </Col>
                  <Col
                    className="col-md-6 col-sm-6 col-xs-9"
                    xs="auto"
                  >
                    <TabContentWidget count="04." title="Создавать веб-приложения на Django" text={'Вы научитесь разбираться в архитектуре <br class="d-none d-lg-block" /> веб-приложений и пользоваться инструментами: <br class="d-none d-lg-block" /> virtualenv, Git, Pycharm и другими. <br class="d-none d-lg-block" /> Изучите основы SQL.'}/>
                  </Col>
                  <div>{toBackend}</div>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <Row>
                  <Col
                    className="col-md-6 col-sm-6 col-xs-9"
                    xs="auto"
                  >
                    <TabContentWidget count="05." title="Создавать пользовательские интерфейсы" text={'Вы научитесь создавать полноценный <br class="d-none d-lg-block" /> пользовательский интерфейс, аналогичный <br class="d-none d-lg-block" /> популярным соцсетям. Будете <br class="d-none d-lg-block" /> создавать интернет магазины.'}/>
                  </Col>
                  <Col
                    className="col-md-6 col-sm-6 col-xs-9"
                    xs="auto"
                    
                  >
                    <TabContentWidget count="06." title="Работать с серверной частью сайтов" text={'Вы научитесь работать с серверами, <br class="d-none d-lg-block" />базами данных, также протестировать <br class="d-none d-lg-block" /> интернет-сервис, отладить ошибки.'}/>
                  </Col>
                  <div>{toFullstack}</div>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Col>
          </Feature.Tab>
        </Tab.Container>
      </Container>
    </Feature>
  )
}

export default FeatureSection
